import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./OurEcosystemSection.styles";
import HeadingDash from "../../components/HeadingDash/HeadingDash";

interface OurEcosystemSection {
  header: string;
  description?: string;
  picture: JSX.Element;
}

const OurEcosystemSection = ({
  header,
  description,
  picture,
}: OurEcosystemSection): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <HeadingDash justify="center" />
      <Typography variant="h2" className={classes.header}>
        {header}
      </Typography>
      {description && (
        <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>
      )}
      {picture}
    </section>
  );
};

export default OurEcosystemSection;
