import React from "react";
import { useStyles } from "./AllFeaturesSection.styles";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../components/HeadingDash/HeadingDash";
import FeaturePaper from "../../components/FeaturePaper/FeaturePaper";

interface AllFeaturesSectionProps {
  heading: string;
  description: string;
  icon: JSX.Element;
  features: string[];
}

const AllFeaturesSection = ({
  heading,
  description,
  icon,
  features,
}: AllFeaturesSectionProps): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={5} className={classes.leftPart}>
          <HeadingDash justify="flex-start" />
          <Typography variant="h2" className={classes.header}>
            {heading}
          </Typography>
          <Typography variant="body2" className={classes.description}>
            {description}
          </Typography>
          <div className={classes.imgWrapper}>{icon}</div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.cardWrapper}>
          <>
            {features.map((feature, index) => (
              <FeaturePaper key={index}>{feature}</FeaturePaper>
            ))}
          </>
        </Grid>
      </Grid>
    </section>
  );
};

export default AllFeaturesSection;
