import React, { LegacyRef } from "react";
import { Box, Button } from "@material-ui/core";
import { useStyles } from "./EmailActionBox.styles";
import { EmailInput } from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "../../../helpers/useYupValidationResolver";
import * as yup from "yup";
import { EpService, useEmailService } from "../../../api/useEmailService";

interface EmailActionBoxProps {
  emailInput: EmailInput;
  service: EpService;
}

const validationSchema = yup.object({
  email: yup.string().required("Required"),
});

const EmailActionBox = ({
  emailInput,
  service,
}: EmailActionBoxProps): JSX.Element => {
  const classes = useStyles();
  const emailAPI = useEmailService();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver });

  const onSubmit = (data: any) => {
    //  TODO replace any type
    try {
      emailAPI.postEmailData(data.email, data.service);
    } catch (err) {
      console.log(err);
      // TODO check error handling for Gatsby
    }
  };

  return (
    <Box padding={"10px"} className={classes.emailWrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <input
          type="text"
          id="email"
          placeholder="Your email address"
          // ref={emailInput as LegacyRef<HTMLInputElement>}
          {...register("email", {
            required: true,
            ref: emailInput as LegacyRef<HTMLInputElement>,
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: "Incorrect email address",
              // TODO fix ref issue, pattern and error handling
            },
          })}
        />
        <input
          hidden
          type="text"
          id="service"
          value={service}
          {...register("service", { required: true })}
        />
        <Button
          color="secondary"
          variant="contained"
          size="large"
          type="submit"
        >
          Book a demo
        </Button>
      </form>
    </Box>
  );
};

export default EmailActionBox;
