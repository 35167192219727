import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  buttonBox: {
    width: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: "20px",
    "@media (max-width:900px)": {
      display: "none",
    },
  },
  scrollButton: {
    background: "rgba(255,255,255,0.1)",
    boxShadow: "none",
    "&:hover": {
      background: "rgba(255,255,255,0.15)",
    },
    "&:active": {
      background: "rgba(255,255,255,0.2)",
    },
    "& svg": {
      transform: "rotate(90deg)",
      color: "#FF9900",
    },
  },
});
