import React from "react";
import { Card } from "@material-ui/core";
import { useStyles } from "./IconCard.styles";

interface Props {
  icon: JSX.Element;
  text: string;
  className?: string;
}

const IconCard = ({ icon, text, className }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Card className={`${classes.card} ${className}`}>
      {icon}
      <span>{text}</span>
    </Card>
  );
};

export default IconCard;
