import React from "react";
import { Box, Fab } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useStyles } from "./ScrollDownButton.styles";
import { NAVBAR_HEIGHT } from "../../const/navbarHeight/navbarHeight";

const ScrollDownButton = (): JSX.Element => {
  const classes = useStyles();

  const scrollDownFullWindowHeight = () => {
    const scrollDownHeight = window.innerHeight - NAVBAR_HEIGHT;
    window.scrollBy(0, scrollDownHeight);
  };

  return (
    <Box className={classes.buttonBox}>
      <Fab
        color="primary"
        aria-label="scroll-down"
        className={classes.scrollButton}
        onClick={scrollDownFullWindowHeight}
      >
        <ArrowForwardIosIcon fontSize="large" />
      </Fab>
    </Box>
  );
};

export default ScrollDownButton;
