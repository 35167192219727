import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: "100vw",
    height: "30vh",
    paddingLeft: "10%",
    paddingRight: "10%",
    border: "none",
    backgroundColor: "#fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center,",
    [theme.breakpoints.up("xs")]: {
      height: "280px",
    },
  },
  p: {
    textAlign: "center",
    fontStyle: "normal",
    margin: "auto",
    fontSize: "16px",
    [theme.breakpoints.up("xs")]: {
      padding: "30px 0",
      width: "90%",
      "& br": {
        display: "none",
      },
    },
    "& b": {
      fontWeight: "bold",
    },
    "& span": {
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
  },
}));
