import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "90px 10%",
      backgroundColor: "#F6F3F0",
    },
    header: {
      marginBottom: theme.spacing(4),
    },
    leftPart: {
      paddingRight: "180px",
      [theme.breakpoints.down("lg")]: {
        paddingRight: "0px",
      },
    },
    imgWrapper: {
      "& img": {
        width: "120%",
        maxWidth: "400px",
        [theme.breakpoints.down("md")]: {
          width: "80vw",
          maxWidth: "300px",
        },
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
    },
    cardWrapper: {
      display: "flex",
      flexWrap: "wrap-reverse",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    description: {
      padding: "25px 0",
      maxWidth: "400px",
    },
  })
);
