import axios from "axios";

export type EpService = "Energy Platform" | "SolarEP" | "EVoEP" | "OptoEP";

export enum EpServicesEnum {
  Ep = "Energy Platform",
  Solar = "SolarEP",
  EVo = "EVoEP",
  Opto = "OptoEP",
}

interface UseEmailStructure {
  postEmailData: (email: string, service: EpService) => void;
}

export const useEmailService = (): UseEmailStructure => {
  const postEmailData = async (email: string, service: EpService) => {
    const { data } = await axios.post(
      "https://getform.io/f/f586afde-fe87-464a-b577-c53fbc13f2f2",
      {
        email,
        service,
      }
    );
    return data;
  };
  return { postEmailData };
};
