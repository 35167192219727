import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 30px 30px rgba(7, 30, 87, 0.05)",
    borderRadius: "20px",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "140px",
      height: "140px",
      margin: "auto",
    },
    "& span": {
      fontSize: "12px",
      fontWeight: 600,
      padding: "10px 10px",
      textAlign: "center",
      lineHeight: "16px",
    },
    "& img": {
      width: "60px",
      paddingTop: "10px",
    },
  },
}));
