import * as React from "react";
import { useStyles } from "./StartingSection.styles";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../components/HeadingDash/HeadingDash";
import EmailActionBox from "../../components/EmailActionBox/EmailActionBox";
import ScrollDownButton from "../../components/ScrollDownButton/ScrollDownButton";
import { EmailInput } from "../../Layout/Layout";
import { EpService } from "../../../api/useEmailService";

interface StartingSectionProps {
  service: EpService;
  emailInputRef: EmailInput;
  title: string;
  subtitle: string;
  description?: string;
  illustration: JSX.Element;
}

const StartingSection = ({
  emailInputRef,
  service,
  title,
  subtitle,
  description,
  illustration,
}: StartingSectionProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={7} className={classes.wrapper}>
          <Typography variant="h1">
            <HeadingDash justify="flex-start" />
            {title}
          </Typography>
          <Typography variant="h3">{subtitle}</Typography>
          {description ? (
            <Typography variant="body2" className={classes.description}>
              {description}
            </Typography>
          ) : null}
          <EmailActionBox emailInput={emailInputRef} service={service} />
        </Grid>
        <Grid item xs={12} md={4} className={classes.illustrationWrapper}>
          {illustration}
        </Grid>
      </Grid>
      <ScrollDownButton />
    </section>
  );
};

export default StartingSection;
