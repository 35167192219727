import * as React from "react";
import { useStyles } from "./SummarySection.styles";
import { Typography } from "@material-ui/core";

interface SummarySectionProps {
  children: JSX.Element | string;
}

const SummarySection = ({ children }: SummarySectionProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Typography variant="body1" className={classes.p}>
        {children}
      </Typography>
    </section>
  );
};
export default SummarySection;
