import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useStyles } from "./FeaturePaper.styles";

interface FeaturePaperProps {
  children: JSX.Element | string;
  className?: string;
}

const FeaturePaper = ({
  children,
  className,
}: FeaturePaperProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Paper className={`${classes.paper} ${className}`}>
      <Typography variant="body2" className={classes.listElem}>
        {children}
      </Typography>
    </Paper>
  );
};

export default FeaturePaper;
