import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  emailWrapper: {
    margin: "40px 10px",
    marginLeft: "10%",
    border: `2px solid ${theme.palette.secondary.main}`,
    boxSizing: "border-box",
    borderRadius: "80px",
    color: "#fff",
    width: "70%",
    height: "8vh",
    padding: 0,
    display: "inline-flex",
    overflow: "hidden",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginTop: "10%",
      width: "80%",
      height: "6vh",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px",
      display: "flex",
      flexDirection: "column",
      border: "none",
      height: "20vh",
      width: "80vw",
      margin: "0 auto",
    },

    "& input": {
      color: "#fff",
      border: "none",
      background: "none",
      width: "80%",
      paddingLeft: "5%",
      fontSize: "20px",
      outline: "none",
      flexGrow: 6,
      "& ::placeholder": {
        color: "#fff",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        border: `2px solid ${theme.palette.secondary.main}`,
        lineHeight: "1rem",
        borderRadius: "80px",
        flexGrow: 3,
        fontSize: "16px",
        textAlign: "center",
        margin: "4px 0",
      },
    },

    "& button": {
      color: "#fff",
      borderRadius: "80px",
      textTransform: "initial",
      fontStyle: "normal",
      width: "40%",
      fontWeight: 800,
      flexGrow: 1,
      margin: "0px",
      padding: "8px",
      fontSize: "1.2rem",
      flexWrap: "nowrap",
      wrap: "nowrap",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "10px",
        flexGrow: 3,
        margin: "4px 0",
      },
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "20vh",
      width: "80vw",
      margin: "0 auto",
    },
  },
}));
