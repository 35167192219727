import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    width: "100vw",
    height: "100vh",
    border: "none",
    fontStyle: "normal",
    color: "#fff",
    "& h1": {
      "& div": {
        "& hr": {
          marginBottom: "16px",
        },
      },
    },
    "& h3": {
      width: "60%",
    },
    "& p": {
      width: "60%",
      marginBottom: "4%",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
  wrapper: {
    margin: "2% 0 0 6vw",
  },
  description: {
    marginLeft: "10%",
  },
  illustrationWrapper: {
    position: "absolute",
    right: "4%",
    bottom: "8%",
    [theme.breakpoints.down("sm")]: {
      bottom: "0px",
    },
    "& img": {
      pointerEvents: "none",
      [theme.breakpoints.down("xs")]: {
        width: "300px",
      },
    },
  },
}));
