import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    padding: "100px 10% 100px 10%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      width: "80%",
      margin: "auto",
      marginTop: "-110px",
      "@media (max-width:900px)": {
        width: "100%",
      },
    },
  },
  header: {
    color: "white",
    textAlign: "center",
  },
  description: {
    color: "white",
    textAlign: "center",
    maxWidth: "650px",
    margin: "24px auto 200px auto",
  },
});
