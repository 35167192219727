import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  paper: {
    borderRadius: "20px",
    flexBasis: "274px",
    flexGrow: 0,
    flexShrink: 0,
    height: "124px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 40px",
    textAlign: "center",
    lineHeight: "24px",
    boxShadow: "0px 30px 30px rgba(7, 30, 87, 0.05)",
  },
  listElem: {
    fontWeight: 500,
  },
});
